import request from '@/utils/request'


export function verifyCard(data) {
	return request({
		url: '/verifyCard',
		method: 'post',
		data
	})
}

export function verifyOrder(data) {
	return request({
		url: '/verifyOrder',
		method: 'post',
		data
	})
}

export function updateData(data) {
	return request({
		url: '/updateData',
		method: 'post',
		data
	})
}
export function feedBack(data) {
	return request({
		url: '/feedBack',
		method: 'post',
		data
	})
}

export function getCardData(data) {
	return request({
		url: '/getCardData',
		method: 'post',
		data
	})
}

export function getOrderList(data) {
	return request({
		url: '/getOrderList',
		method: 'post',
		data
	})
}
export function getOrderListNew(data) {
	return request({
		url: '/getOrderListNew',
		method: 'post',
		data
	})
}
export function getOrderDetail(data) {
	return request({
		url: '/getOrderDetail',
		method: 'post',
		data
	})
}

export function getBanner(data) {
	return request({
		url: '/getBanner',
		method: 'post',
		data
	})
}

export function ocrAddress(data) {
	return request({
		url: '/ocrAddress',
		method: 'post',
		data
	})
}

export function getCardProgress(data) {
	return request({
		url: '/getCardProgress',
		method: 'post',
		data
	})
}
